import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import Button from '../../../atoms/Button'
import Input from '../../../atoms/Input'
import { IModal } from './types'

const Modal: FC<IModal> = ({ open, onAbort, onSave }) => {
  const { t } = useTranslation('common')
  const [newPreset, setNewPreset] = useState('')
  const handleAbortNewPreset = () => {
    onAbort()
    setNewPreset('')
  }

  return (
    <Dialog open={open} onClose={handleAbortNewPreset}>
      <DialogTitle>{t('companySearch.newPresetTitle')}</DialogTitle>
      <DialogContent>
        <Grid container spacing={1} p={2}>
          <Grid item xs={12}>
            <Input
              noPlaceholder
              size="small"
              label={t('companySearch.newPresetName')}
              value={newPreset}
              InputLabelProps={{ shrink: true }}
              placeholder={t('companySearch.presetPlaceholder')}
              onChange={e => setNewPreset(e.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ padding: 2, display: 'flex', justifyContent: 'center' }}>
        <Button color="primary" onClick={() => onSave?.(newPreset)}>
          {t('save')}
        </Button>
        <Button color="secondary" onClick={handleAbortNewPreset}>
          {t('abort')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default Modal
