import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import { Info, Warning } from '@mui/icons-material'
import { Box, Dialog, DialogContent, DialogActions, Grid, Typography, Tooltip } from '@mui/material'
import { OutlinedCross } from '../../../assets/icons'
import { COLLISION_DETECTION_QUERY } from '../../../graphql/queries'
import { Button } from '../../atoms'

interface CollisionDetectionProps {
  disabled?: boolean
}

const CollisionDetection: React.FC<CollisionDetectionProps> = ({ disabled }) => {
  const { t } = useTranslation(['collisionDetection', 'common'])
  const [open, setOpen] = useState(false)
  const { watch, setValue } = useFormContext()

  const firstname = watch('conflictPartnerFirstname')
  const lastname = watch('conflictPartnerLastname')
  const postalCode = watch('conflictPartnerPostalCode')
  const needsCollisionDetection = watch('needsCollisionDetection')

  const [startCollisionDetection, { called, loading, data }] = useLazyQuery(
    COLLISION_DETECTION_QUERY,
    {
      fetchPolicy: 'network-only'
    }
  )

  const onButtonClick = () => {
    startCollisionDetection({
      variables: {
        firstname,
        lastname,
        postalCode
      }
    })
  }

  const onCollisionDetectedButtonClick = () => {
    setValue('collisionConfirmed', true, { shouldDirty: true })
    setValue('completeReason', 'service_call', { shouldDirty: true })
    setValue('closingResult', 'collision', { shouldDirty: true })
    setOpen(false)
    const submitButton = document.getElementById('editor-modal-save-button')
    submitButton?.click()
  }

  const onNoCollisionButtonClick = () => {
    setValue('noCollisionByHuman', true, { shouldDirty: true })
    setOpen(false)
  }

  const noCollisionByHuman = watch('noCollisionByHuman')

  useEffect(() => {
    if (called && !loading && data && (data?.m2CasesByCollisionDetection?.length || 0) > 0) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [data, loading, called])

  if (disabled) {
    if (noCollisionByHuman) {
      return (
        <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body2" sx={{ width: 'max-content' }}>
            <em>{t('collisionDetection:markedAsNoCollision')}</em>
          </Typography>
        </Grid>
      )
    }
    return null
  }

  return (
    <>
      <Grid container item>
        <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
          <Button
            id="fakeSubmitButton"
            color="primary"
            onClick={onButtonClick}
            fullWidth
            disabled={!needsCollisionDetection || !firstname || !lastname || !postalCode}
          >
            {t('startCollisionDetection')}
          </Button>
        </Grid>
        <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title={t('collisionDetection:hint')} sx={{ mx: 2 }}>
            <Info />
          </Tooltip>
        </Grid>
        {noCollisionByHuman ? (
          <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body2" sx={{ width: 'max-content' }}>
              <em>{t('collisionDetection:markedAsNoCollision')}</em>
            </Typography>
          </Grid>
        ) : null}
      </Grid>
      <Dialog open={open} maxWidth="lg" data-testid="sms-modal">
        <DialogContent>
          <>
            <Typography variant="h5">{t('collisionDetection:title')}</Typography>
            <Typography variant="caption" sx={{ pt: 1 }}>
              {t('collisionDetection:subtitle')}
            </Typography>
            {(data?.m2CasesByCollisionDetection?.length || 0) > 0 ? (
              <Grid container sx={{ mt: 4 }}>
                <Grid spacing={2} item xs={12} container>
                  <Grid item xs={12} md={4}>
                    <Typography variant="body2">
                      <strong>{t('collisionDetection:claimDate')}</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="body2">
                      <strong>{t('collisionDetection:comments')}</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="body2">
                      <strong>{t('collisionDetection:legalArea')}</strong>
                    </Typography>
                  </Grid>
                </Grid>
                {data.m2CasesByCollisionDetection.map((m2Case: any) => (
                  <Grid spacing={2} item xs={12} container>
                    <Grid item xs={12} md={4}>
                      <Typography variant="body2">{m2Case.claimDate}</Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography variant="body2">{m2Case.comments}</Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography variant="body2">{m2Case.m2LegalArea?.title}</Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            ) : null}
          </>
        </DialogContent>
        <DialogActions>
          <Box>
            <Button
              color="secondary"
              sx={{ marginRight: '15px' }}
              onClick={onNoCollisionButtonClick}
              startIcon={<OutlinedCross />}
            >
              {t('collisionDetection:noCollision')}
            </Button>
            <Button
              color="primary"
              sx={{ marginRight: '15px' }}
              onClick={onCollisionDetectedButtonClick}
              startIcon={<Warning />}
            >
              {t('collisionDetection:collisionDetected')}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CollisionDetection
