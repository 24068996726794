import React from 'react'
import { useTranslation } from 'react-i18next'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import { IconButton, Tooltip } from '@mui/material'

interface IMailToProps {
  email?: string
  subject?: string
  body?: string
}

const isValidEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(email)
}

const MailTo: React.FC<IMailToProps> = ({ email, subject = '', body = '' }) => {
  const { t } = useTranslation('common')
  const validEmail = email ? isValidEmail(email) : false

  const onClick = () => {
    window.location.href = `mailto:${email}?subject=${subject}&body=${body}`
  }

  return (
    <Tooltip title={t('openEmailClient')} placement="top">
      <IconButton size="small" onClick={onClick} disabled={!validEmail}>
        <MailOutlineIcon />
      </IconButton>
    </Tooltip>
  )
}

export default MailTo
