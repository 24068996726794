import React from 'react'
import { Badge as MuiBadge, BadgeProps as MuiBadgeProps } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { SxProps } from '@mui/system'

export interface BadgeProps extends Omit<MuiBadgeProps, 'component'> {
  color?: 'primary' | 'secondary' | 'error' | 'warning' | 'success'
  children?: JSX.Element | string
  sx?: SxProps<Theme>
}

const Badge: React.FC<BadgeProps> = props => {
  const { color, children, sx } = props
  return (
    <MuiBadge {...props} color={color} sx={sx}>
      {children}
    </MuiBadge>
  )
}

export default Badge
