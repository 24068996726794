import React, { useCallback, useState, useEffect } from 'react'
import { FileRejection, useDropzone, Accept } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { AddCircleOutline } from '@mui/icons-material'
import { Box, FormLabel, Typography } from '@mui/material'
import { DirectUpload } from '@rails/activestorage'
import config from '../../../config'
import { FileType } from '../../../utils/fixtures'
import { Button } from '../../atoms'
import MessageBox from '../MessageBox'
import UploadedFiles from './components/UploadedFiles'
import classes from './styles'

const DIRECT_UPLOAD_URL = `${config.apollo.baseUrl}/direct_uploads`

export interface UploaderProps {
  accept?: Accept
  label?: string
  maxFiles?: number
  maxSize?: number
  disabled?: boolean
  noFileText?: string
  onFilesChanged: (files: FileType[] | null | undefined) => void
  onFileDeleted: (file: FileType) => void
  value?: FileType[]
  multiple?: boolean
}

const Uploader = React.forwardRef<HTMLDivElement, UploaderProps>(
  (
    {
      accept,
      label,
      maxSize = 100 * 1024 * 1024, // 100MB
      disabled = false,
      maxFiles = 0,
      noFileText,
      onFilesChanged,
      onFileDeleted,
      value = [],
      multiple = false
    },
    ref
  ) => {
    const { t } = useTranslation('common')
    const [uploading, setUploading] = useState<boolean>(false)

    const [files, setFiles] = useState<FileType[] | undefined>(value)

    useEffect(() => {
      onFilesChanged?.(files)
    }, [files, onFilesChanged])

    const deleteFile = (file: FileType, e: React.MouseEvent<Element, MouseEvent>) => {
      e.stopPropagation()
      setFiles(files?.filter(f => f.id !== file.id || f.path !== file.path))
      onFileDeleted?.(file)
    }

    const [error, setError] = useState({ visible: false, message: '' })

    const onDrop = useCallback(
      (newFiles: FileType[]) => {
        setError({ visible: false, message: '' })
        setUploading(true)
        newFiles.forEach((newFile, i: number) => {
          const upload = new DirectUpload(newFile, DIRECT_UPLOAD_URL)

          return upload.create((err: any, newBlob: any) => {
            if (err) {
              console.error('err', err)
              return
            }
            setFiles(oldFiles => [
              ...(oldFiles || []),
              {
                ...newBlob,
                name: newFile.filename,
                attachmentUrl: URL.createObjectURL(newFile)
              } as FileType
            ])
            if (newFiles.length - 1 === i) setUploading(false)
          })
        })
      },
      [setFiles]
    )
    const onDropRejected = (e: FileRejection[]) => {
      console.debug(e)
      setError({ visible: true, message: e?.[0]?.file?.name || '' })
      setUploading(false)
    }
    const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      onDropRejected,
      accept,
      maxFiles,
      maxSize,
      multiple
    })

    return (
      <Box
        sx={{
          ...classes.container,
          ...(disabled ? classes.disabled : {})
        }}
      >
        <Box ref={ref} {...getRootProps()} sx={classes.uploader}>
          <FormLabel component="label">
            <Button
              color="primary"
              startIcon={<AddCircleOutline />}
              sx={{ margin: '20px 0px 20px 0px' }}
              disabled={disabled}
              loading={uploading}
              loadingPosition="start"
            >
              {label || t('uploadButtonLabel')}
            </Button>
          </FormLabel>
          {maxFiles === 0 || (files?.length || 0) < (maxFiles || 0) ? (
            <input {...getInputProps()} disabled={disabled} />
          ) : null}
          {(files?.length || 0) <= 0 && noFileText && (
            <Typography sx={classes.noFileText}>{noFileText}</Typography>
          )}
        </Box>
        <UploadedFiles files={files} onDelete={deleteFile} />
        {error.visible && (
          <MessageBox
            open={error.visible}
            variant="error"
            message={t('errorUploadingFileWithName', { fileName: error.message })}
            onClose={() => setError({ visible: false, message: '' })}
          />
        )}
      </Box>
    )
  }
)

export default Uploader
