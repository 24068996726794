import { ApolloClient, ApolloLink, from, HttpLink, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import * as Sentry from '@sentry/browser'
import { SentryLink } from 'apollo-link-sentry'
import moment from 'moment'
import config from './config'
import {
  setToken,
  setUid,
  setClient,
  clearUserData,
  getExpiry,
  setExpiry,
  getToken,
  getUid,
  getClient,
  setTimer
} from './utils/helper'

const getTimeLeft = () => moment.unix(getExpiry()).diff(moment())
const httpLink = new HttpLink({ uri: config.apollo.networkInterface })

// Setup the header for the request
const middlewareAuthLink = new ApolloLink((operation, forward) => {
  const client = getClient()
  if (!client) {
    clearUserData()
  }

  operation.setContext({
    headers: {
      'Content-type': 'application/json',
      'access-token': getToken(),
      'token-type': 'Bearer',
      uid: getUid(),
      client
    }
  })

  return forward(operation)
})

const sentryLink = new SentryLink({
  attachBreadcrumbs: {
    includeError: true
  }
})

// After the backend responds, we take the refreshToken from headers if it exists, and save it in the localStorage.
const afterwareLink = new ApolloLink((operation, forward) =>
  forward(operation).map(response => {
    const context = operation.getContext()
    const {
      response: { headers }
    } = context

    if (headers) {
      const newToken = headers.get('access-token')
      if (newToken?.length) setToken(newToken)
      const newUid = headers.get('uid')
      if (newUid?.length) setUid(newUid)
      const newClient = headers.get('client')
      if (newClient?.length) {
        setClient(newClient)
      }
      const newExpiry = headers.get('expiry')
      if (newExpiry?.length) {
        console.debug('Setting new expiry: ', newExpiry)
        setExpiry(newExpiry)
      } else {
        console.debug('NOT setting new expiry: ', newExpiry)
      }
    }

    return response
  })
)

const errorLink = onError(({ operation, graphQLErrors, networkError, response }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (message === 'access denied' && getTimeLeft() - 10000 < 10000) {
        setTimer(
          JSON.stringify({
            last_expiry: moment.unix(getExpiry()).toLocaleString(),
            date: moment().toLocaleString(),
            message: 'access denied'
          })
        )
        Sentry.withScope(scope => {
          scope.setExtras({
            user: getUid(),
            response: JSON.stringify(response),
            graphQLErrors: JSON.stringify(graphQLErrors),
            networkError: JSON.stringify(networkError),
            operation: JSON.stringify(operation),
            timeLeft: getTimeLeft(),
            message,
            path
          })
          const transactionId = operation
            .getContext()
            ?.request?.http?.headers?.get('x-transaction-id')
          if (transactionId) scope.setTransaction(transactionId)

          // NOTE - CK: Integrate it again if its still a problem
          // Sentry.captureException(new Error('access denied WITHOUT time left'))
        })
        clearUserData()
        window.location.replace('/login')
      }
      if (message === 'access denied' && getTimeLeft() - 10000 > 10000) {
        Sentry.withScope(scope => {
          scope.setExtras({
            user: getUid(),
            response: JSON.stringify(response),
            graphQLErrors: JSON.stringify(graphQLErrors),
            networkError: JSON.stringify(networkError),
            operation: JSON.stringify(operation),
            timeLeft: getTimeLeft(),
            message,
            path
          })
          const transactionId = operation
            .getContext()
            ?.request?.http?.headers?.get('x-transaction-id')
          if (transactionId) scope.setTransaction(transactionId)

          Sentry.captureException(new Error('access denied WITH time left'))
        })
        clearUserData()
        window.location.replace('/login')
      }
      if (message === 'forbidden' && getTimeLeft() - 10000 > 10000) {
        window.location.replace('/cases')
      }
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    })
  }

  if (networkError) console.warn(`[Network error]: ${networkError}`)
})

const cache = new InMemoryCache()

const client = new ApolloClient({
  link: from([sentryLink, middlewareAuthLink, afterwareLink, errorLink, httpLink]),
  cache
})

export default client
