import React, { useRef, useState, useLayoutEffect } from 'react'
import { AppBar, Box, Toolbar, Typography } from '@mui/material'
import { Subtitle } from '../../atoms'

interface TopBarProps {
  title?: string | React.ReactNode
  subTitle?: string | React.ReactNode
  position?: 'fixed' | 'absolute' | 'sticky' | 'static' | 'relative'
  zIndex?: number
  marginLeft?: string
  width?: string
  height?: string
  extra?: React.ReactNode
}

const TopBar: React.FC<TopBarProps> = ({
  title = null,
  subTitle = null,
  position = 'static',
  extra = null,
  zIndex = 980,
  marginLeft = '381px',
  width = 'calc(100% - 381px)',
  height = '180'
}) => {
  const extraBoxRef = useRef<HTMLDivElement>(null)
  const [extraBoxSize, setExtraBoxSize] = useState({ width: 0, height: 0 })

  useLayoutEffect(() => {
    if (extraBoxRef.current) {
      const { offsetWidth, offsetHeight } = extraBoxRef.current
      setExtraBoxSize({ width: offsetWidth, height: offsetHeight })
    }
  }, [extra])

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position={position}
        sx={{
          zIndex,
          backgroundColor: 'transparent'
        }}
      >
        <Toolbar
          sx={theme => ({
            width,
            height,
            marginLeft,
            alignItems: 'flex-end',
            paddingTop: 4,
            paddingLeft: 4,
            paddingRight: 4,
            paddingBottom: 4,
            color: 'common.primary',
            backgroundColor: 'white',
            justifyContent: 'space-between',
            [theme.breakpoints.up('md')]: {
              boxShadow: '0 4px 16px 0 lightgrey',
              maxWidth: width
            },
            [theme.breakpoints.down('md')]: {
              height: 'auto',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start'
            }
          })}
        >
          <Box
            sx={theme => ({
              [theme.breakpoints.up('md')]: {
                maxWidth: `calc(100% - 10px - ${extraBoxSize.width}px)`
              }
            })}
          >
            <Subtitle>{subTitle}</Subtitle>
            <Typography variant="h1" color="primary" component="h1">
              {title}
            </Typography>
          </Box>
          {extra && (
            <Box
              ref={extraBoxRef}
              sx={theme => ({
                display: 'flex',
                alignItems: 'flex-end',
                [theme.breakpoints.up('md')]: { flexShrink: 0 }
              })}
            >
              {extra}
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default TopBar
