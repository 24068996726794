import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'
import Backend from 'i18next-http-backend'

i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
    backend: {
      // for all available options read the backend's repository readme file
      addPath: '/public/locales/{{lng}}/{{ns}}.json'
    },
    lng: 'de',
    fallbackLng: ['de'],
    debug: process.env.REACT_APP_ENV === 'development',
    defaultNS: 'common',
    expirationTime: 8 * 60 * 60 * 1000, // 8 hours
    namespaces: [
      'attorney',
      'auth',
      'auth',
      'billing',
      'caseProperty',
      'casesOverview',
      'chart1',
      'chart2',
      'chart3',
      'chart4',
      'chart5',
      'closing',
      'collisionDetection',
      'common',
      'contact',
      'dashboard',
      'documentationList',
      'editorView',
      'firstContact',
      'forwardRightmart',
      'incomingCall',
      'mediatorInfo',
      'messageToM2',
      'notice',
      'processingProperty',
      'reportProperty',
      'requestCoverage',
      'sms',
      'table',
      'userProfile',
      'validation'
    ],
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })

export default i18n
