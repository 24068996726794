import React, { useState, useRef, useEffect } from 'react'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { Box, Tooltip, Typography } from '@mui/material'

interface CaseTitleProps {
  tooltip?: boolean
  tooltipContent?: string
  title: string | null | React.ReactElement
  subtitle?: string | null | React.ReactElement
}

const CaseTitle: React.FC<CaseTitleProps> = ({ tooltip, tooltipContent, title, subtitle }) => {
  const [overflowed, setOverflowed] = useState(false)
  const textElement = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleResize = () => {
      if (textElement?.current) {
        setOverflowed(textElement.current.scrollWidth > textElement.current.clientWidth)
      }
    }
    if (typeof ResizeObserver === 'undefined') {
      return () => {}
    }
    const resizeObserver = new ResizeObserver(handleResize)
    const currentElement = textElement.current
    if (currentElement) {
      resizeObserver.observe(currentElement)
    }

    // Initial check
    handleResize()

    return () => {
      if (currentElement) {
        resizeObserver.unobserve(currentElement)
      }
    }
  }, [title, subtitle, tooltip, tooltipContent])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={theme => ({
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          [theme.breakpoints.down('md')]: {
            fontSize: '1.25rem'
          }
        })}
      >
        {tooltip && (
          <Tooltip title={tooltipContent}>
            <AccountCircleIcon
              sx={theme => ({
                marginRight: '8px',
                fill: theme.palette.primary.main,
                ':hover': { fill: theme.palette.petrol }
              })}
            />
          </Tooltip>
        )}

        <Tooltip title={title} disableHoverListener={!overflowed}>
          <Box
            ref={textElement}
            sx={theme => ({
              [theme.breakpoints.up('md')]: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }
            })}
          >
            {title}
          </Box>
        </Tooltip>
      </Box>

      {subtitle && (
        <Typography color="primary" sx={{ pt: 0, marginLeft: '35px' }} variant="h4">
          {subtitle}
        </Typography>
      )}
    </Box>
  )
}

export default CaseTitle
