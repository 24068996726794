import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Box, Divider, Grid, Typography } from '@mui/material'
import moment from 'moment'
import { ArrowDown, ArrowUp, CurvedArrowLeft } from '../../../assets/icons'
import { getCommunicationEnum } from '../../../utils/constants'
import { getNumberFromString } from '../../../utils/helper'
import { Button, CheckBox, DateRangePicker } from '../../atoms'
import SearchField from '../SearchField'

const DATE_EMPTY_VALUE = 'NULL'
const EMPTY_VALUE = 'NULL_OR_EMPTY'
const DATE_NOT_EMPTY_VALUE = '!NULL'
const NOT_EMPTY_VALUE = '!NULL_OR_EMPTY'

const ColumnMenu = React.memo(props => {
  const { colDef: currentColumn, filter, setSort, setFilter, hideMenu } = props
  const { t } = useTranslation(['table', 'common'])
  const { filterable, sortable, type, field } = currentColumn

  const startKey = `${field}Start`
  const endKey = `${field}End`

  const onSortButtonClicked = (direction, e) => {
    setSort([
      {
        sort: direction,
        field: currentColumn.field
      }
    ])
    hideMenu?.(e)
  }
  const onResetButtonClicked = e => {
    setFilter(() => {
      const oldFilter = { ...filter }
      if (currentColumn?.multiple) {
        delete oldFilter[`${field}s`]
      }
      delete oldFilter.agentEmployeeHohner
      delete oldFilter[field]
      delete oldFilter[startKey]
      delete oldFilter[endKey]
      return oldFilter
    })
    hideMenu?.(e)
  }

  const getStartFromFilter = useCallback(() => {
    if (!filter?.[startKey]) return null
    const dateString = filter?.[startKey]?.slice(2)
    const dateMoment = moment(dateString)
    return dateMoment.isValid() ? dateMoment.toDate() : null
  }, [filter, startKey])

  const getEndFromFilter = useCallback(() => {
    if (!filter?.[endKey]) return null
    const dateString = filter?.[endKey]?.slice(2)
    const dateMoment = moment(dateString)
    return dateMoment.isValid() ? dateMoment.toDate() : null
  }, [filter, endKey])

  const [startDate, setStartDate] = useState(getStartFromFilter())
  const [endDate, setEndDate] = useState(getEndFromFilter())

  useEffect(() => {
    setStartDate(getStartFromFilter())
  }, [getStartFromFilter, setStartDate, filter])

  useEffect(() => {
    setEndDate(getEndFromFilter())
  }, [getEndFromFilter, setEndDate, filter])

  const onStartDateChange = newStartDate => {
    setStartDate(newStartDate)
  }
  const onEndDateChange = newEndDate => {
    setEndDate(newEndDate)
  }

  const onFilterButtonClicked = e => {
    generateStartEndFilters(e)
  }

  const generateStartEndFilters = e => {
    setFilter({
      ...filter,
      [startKey]: moment(startDate).isValid()
        ? `> ${moment(startDate).format('YYYY-MM-DD')}`
        : null,
      [endKey]: moment(endDate).isValid() ? `< ${moment(endDate).format('YYYY-MM-DD')}` : null
    })
    hideMenu?.(e)
  }

  const renderSortButtons = (
    <Box m={2}>
      <Typography variant="overline">{t('sorting')}</Typography>
      <Grid justifyContent="space-between" container>
        <Button
          variant="outlined"
          color="secondary"
          onClick={e => onSortButtonClicked('asc', e)}
          startIcon={<ArrowUp />}
        >
          {t('ascending')}
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={e => onSortButtonClicked('desc', e)}
          sx={{ marginLeft: 1 }}
          startIcon={<ArrowDown />}
        >
          {t('descending')}
        </Button>
      </Grid>
    </Box>
  )
  const renderBooleanFilter = () => (
    <Box m={2}>
      <Typography variant="overline">{t('filtering')}</Typography>
      <Grid container justifyContent="space-between" />
      <CheckBox
        checked={filter?.[field] === true}
        onChange={() =>
          setFilter({ ...filter, [field]: filter?.[field] === true ? undefined : true })
        }
        name="filter"
        label={t('filterOnlyYes')}
      />
      <CheckBox
        checked={filter?.[field] === false}
        onChange={() => {
          setFilter({ ...filter, [field]: filter?.[field] === false ? undefined : false })
        }}
        name="filter"
        label={t('filterOnlyNo')}
      />
    </Box>
  )
  const renderTimeRange = () => (
    <Box m={2}>
      <Typography variant="overline">{t('filtering')}</Typography>
      <Grid container justifyContent="space-between">
        <DateRangePicker
          onStartDateChange={onStartDateChange}
          onEndDateChange={onEndDateChange}
          startDate={startDate}
          endDate={endDate}
        />
      </Grid>
      <Grid container>
        <Box mt={2} mb={1}>
          <Button
            m="auto"
            size="small"
            variant="contained"
            color="primary"
            onClick={onFilterButtonClicked}
          >
            {t('common:save')}
          </Button>
        </Box>
      </Grid>
    </Box>
  )

  const renderCheckboxFilterField = () => (
    <Box m={2}>
      <Typography variant="overline">{t('filtering')}</Typography>
      <Box sx={{ maxHeight: '200px', overflowY: 'scroll' }}>
        <Box>
          {currentColumn?.options.map(option => (
            <CheckBox
              sx={{ display: 'block' }}
              checked={
                currentColumn?.multiple
                  ? filter?.[`${field}s`]?.includes(option.value)
                  : filter?.[field] === option.value
              }
              onChange={val => {
                let newFilter = val ? option.value : undefined
                if (currentColumn?.multiple) {
                  newFilter = [...(filter?.[`${field}s`] || [])]
                  if (val) {
                    newFilter.push(option.value)
                  } else {
                    const index = newFilter.indexOf(option.value)
                    if (index > -1) {
                      newFilter.splice(index, 1)
                    }
                  }
                }
                setFilter({
                  ...filter,
                  [currentColumn?.multiple ? `${field}s` : field]: newFilter.length
                    ? newFilter
                    : undefined
                })
              }}
              key={option.value}
              label={option.label}
              name={option.label}
            />
          ))}
        </Box>
      </Box>
    </Box>
  )

  const renderCasePolicyholderCommunicationViaCustomerPortalCheckBoxes = () => (
    <Box m={2}>
      <Typography variant="overline">{t('filtering')}</Typography>
      <Box sx={{ maxHeight: '200px', overflowY: 'scroll' }}>
        <Box>
          {getCommunicationEnum().map(option => (
            <CheckBox
              sx={{ display: 'block' }}
              checked={filter?.casePolicyholderCommunicationViaCustomerPortal === option.value}
              onChange={val =>
                setFilter({
                  ...filter,
                  casePolicyholderCommunicationViaCustomerPortal: val ? option.value : undefined
                })
              }
              key={option.value}
              label={option.label}
              name={option.label}
            />
          ))}
        </Box>
      </Box>
    </Box>
  )

  const renderFilter = () => {
    const numberTypes = ['euro', 'dropdownNumber', 'number']
    const fieldName = currentColumn?.multiple ? `${field}s` : field
    const dropdownProps = { minWidth: 100 }
    const defaultProps = { minWidth: 300 }

    return (
      <Box m={2}>
        <Typography variant="overline">{t('filtering')}</Typography>
        <Grid container justifyContent="space-between">
          <SearchField
            id={fieldName}
            {...(['dropdown', 'dropdownNumber'].includes(type) ? dropdownProps : defaultProps)}
            defaultValue={filter?.[fieldName] || []}
            onSubmit={(search, e) => {
              e.stopPropagation()
              const newFilter = { [fieldName]: undefined }
              let newValue = search

              if (numberTypes.includes(type)) {
                newValue = getNumberFromString(search)
              }
              if (numberTypes.includes(type)) {
                newFilter[fieldName] = !!newValue ? newValue : undefined
              } else {
                newFilter[fieldName] = newValue.length ? newValue : undefined
              }
              setFilter({
                ...filter,
                ...newFilter
              })
              hideMenu(e)
              return false
            }}
            query={currentColumn.query}
            queryOptions={currentColumn.queryOptions}
            options={currentColumn.options}
            noQuery={currentColumn.noQuery}
            type={type}
            multiple={currentColumn?.multiple}
          />
        </Grid>
      </Box>
    )
  }

  const renderEmployeeFilter = () => (
    <Box>
      <Grid container justifyContent="space-between" />
      <CheckBox
        sx={{ display: 'block' }}
        checked={filter?.agentName === EMPTY_VALUE}
        onChange={val => {
          setFilter({
            ...filter,
            agentName: val ? EMPTY_VALUE : undefined,
            agentEmployeeHohner: val ? undefined : filter?.agentEmployeeHohner
          })
        }}
        label={t('filterEmptyValues')}
        name={filter?.agentName}
      />
      <CheckBox
        sx={{ display: 'block' }}
        checked={filter?.agentName === NOT_EMPTY_VALUE}
        onChange={val => {
          setFilter({
            ...filter,
            agentName: val ? NOT_EMPTY_VALUE : undefined,
            agentEmployeeHohner: val ? undefined : filter?.agentEmployeeHohner
          })
        }}
        label={t('filterNotEmptyValues')}
        name={filter?.agentName}
      />
      <CheckBox
        checked={filter?.agentEmployeeHohner === true}
        onChange={() =>
          setFilter({
            ...filter,
            agentEmployeeHohner: filter?.[field] === true ? undefined : true,
            agentName: filter?.[field] === true ? filter?.agentName : undefined
          })
        }
        name="filter"
        label={t('employeeHohner')}
      />
      <CheckBox
        checked={filter?.agentEmployeeHohner === false}
        onChange={() => {
          setFilter({
            ...filter,
            agentEmployeeHohner: filter?.agentEmployeeHohner === false ? undefined : false,
            agentName: filter?.[field] === false ? filter?.agentName : undefined
          })
        }}
        name="filter"
        label={t('notEmployeeHohner')}
      />
    </Box>
  )

  const renderEmptyFilter = () =>
    dateTypes.includes(type) ? (
      <CheckBox
        sx={{ display: 'block' }}
        checked={filter?.[field] === DATE_EMPTY_VALUE}
        onChange={val => {
          setFilter({ ...filter, [field]: val ? DATE_EMPTY_VALUE : undefined })
        }}
        label={t('filterEmptyValues')}
        name={filter?.[field]}
      />
    ) : (
      <CheckBox
        sx={{ display: 'block' }}
        checked={filter?.[field] === EMPTY_VALUE}
        onChange={val => {
          setFilter({
            ...filter,
            [field]: val ? EMPTY_VALUE : undefined,
            ...(currentColumn?.multiple && { [`${field}s`]: undefined })
          })
        }}
        label={t('filterEmptyValues')}
        name={filter?.[field]}
      />
    )

  const renderNotEmptyFilter = () =>
    dateTypes.includes(type) ? (
      <CheckBox
        sx={{ display: 'block' }}
        checked={filter?.[field] === DATE_NOT_EMPTY_VALUE}
        onChange={val => {
          setFilter({ ...filter, [field]: val ? DATE_NOT_EMPTY_VALUE : undefined })
        }}
        label={t('filterNotEmptyValues')}
        name={filter?.[field]}
      />
    ) : (
      <CheckBox
        sx={{ display: 'block' }}
        checked={filter?.[field] === NOT_EMPTY_VALUE}
        onChange={val =>
          setFilter({
            ...filter,
            [field]: val ? NOT_EMPTY_VALUE : undefined,
            ...(currentColumn?.multiple && { [`${field}s`]: undefined })
          })
        }
        label={t('filterNotEmptyValues')}
        name={filter?.[field]}
      />
    )

  const dateTypes = ['date', 'dateTime']
  const nonNullableFields = [
    'processingState',
    'caseCaseState',
    'cost',
    'caseCost',
    'additionalCost',
    'caseAdditionalCost',
    'caseDisputeValue',
    'caseCustomerRating',
    'receivedPayment',
    'casePolicyholderCommunicationViaCustomerPortal',
    'agentRole',
    'agentName',
    'assignedFrom',
    'casePolicyholderCasesCount'
  ]
  const shouldRenderEmptyCheckbox = type !== 'boolean' && !nonNullableFields.includes(field)
  const shouldRenderNotEmptyCheckbox = type !== 'boolean' && !nonNullableFields.includes(field)

  return (
    <Box
      role="none"
      onKeyDown={e => e.stopPropagation()}
      onKeyUp={e => e.stopPropagation()}
      onKeyPressed={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
      onWheel={e => e.stopPropagation()}
      sx={{
        flexGrow: 1,
        minWidth: '293px'
      }}
    >
      {sortable && (
        <>
          {renderSortButtons}
          <Box mt={4}>
            <Divider
              sx={{
                marginLeft: 1,
                marginRight: 1
              }}
            />
          </Box>
        </>
      )}
      {filterable && type === 'checkbox' && renderCheckboxFilterField()}
      {filterable &&
        field === 'casePolicyholderCommunicationViaCustomerPortal' &&
        renderCasePolicyholderCommunicationViaCustomerPortalCheckBoxes()}
      {filterable &&
        ![...dateTypes, 'boolean'].includes(type) &&
        !['casePolicyholderCommunicationViaCustomerPortal', 'agentRole'].includes(field) &&
        renderFilter()}
      {filterable && dateTypes.includes(type) && renderTimeRange()}
      {filterable && type === 'boolean' && renderBooleanFilter()}
      {filterable && (
        <Box pb={2} pl={2}>
          <Box>{shouldRenderEmptyCheckbox && renderEmptyFilter()}</Box>
          <Box>{shouldRenderNotEmptyCheckbox && renderNotEmptyFilter()}</Box>
          <Box>{field === 'agentName' && renderEmployeeFilter()}</Box>
          <Box>
            <Button
              m="auto"
              startIcon={<CurvedArrowLeft />}
              size="small"
              variant="contained"
              color="secondary"
              onClick={onResetButtonClicked}
            >
              {t('resetFilter')}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  )
})
ColumnMenu.propTypes = {
  colDef: PropTypes.object,
  hideMenu: PropTypes.func,
  filter: PropTypes.object,
  setSort: PropTypes.func,
  setFilter: PropTypes.func
}
ColumnMenu.defaultProps = {
  colDef: {
    width: 250,
    hide: false,
    sortable: false,
    resizable: true,
    filterable: true,
    type: 'string',
    align: 'left',
    filterOperators: [
      {
        value: 'contains'
      },
      {
        value: 'equals'
      },
      {
        value: 'startsWith'
      },
      {
        value: 'endsWith'
      }
    ],
    field: 'note',
    headerName: 'Hinweis'
  },
  hideMenu: () => {},
  setSort: () => {},
  setFilter: () => {},
  filter: null
}

export default ColumnMenu
