import React, { useState, useEffect, useRef, forwardRef } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Autocomplete as MAutocomplete, TextField } from '@mui/material'
import debounce from 'lodash/debounce'

export interface IAutoComplete {
  query: any
  queryOptions: any
  onSelected?: (value: any) => void
  onChange?: (value: any) => void
  value?: string
  label?: string
}

const AutoComplete = forwardRef(
  (
    { query, queryOptions, onSelected, onChange, value, label }: IAutoComplete,
    ref
  ): JSX.Element => {
    const [results, setResults] = useState<any[]>([])
    const [inputValue, setInputValue] = useState(value || '')

    const [executeSearch] = useLazyQuery(query, {
      fetchPolicy: 'network-only',
      ...queryOptions,
      onCompleted: data => {
        const { collection } = data?.[Object.keys(data)[0]] || {}
        if (collection) {
          setResults(collection.map((item: any) => ({ ...item })))
        } else {
          setResults([])
        }
      }
    })

    const debouncedSearch = useRef(
      debounce((val: string) => {
        if (val.length >= 3) {
          executeSearch({ variables: { filter: { textSearch: val } } })
        } else {
          setResults([])
        }
      }, 300)
    ).current

    useEffect(() => {
      debouncedSearch(inputValue)
      return () => {
        debouncedSearch.cancel()
      }
    }, [inputValue, debouncedSearch])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value)
      onChange?.(event.target.value)
    }

    const handleSelectionChange = (event: React.ChangeEvent<{}>, val: any | null) => {
      if (val) {
        onChange?.(queryOptions?.valueField ? val[queryOptions?.valueField] : val.id)
        onSelected?.(val)
      }
    }

    return (
      <MAutocomplete
        filterOptions={x => x}
        size="small"
        freeSolo
        options={results.map((option: any) => option)}
        getOptionLabel={option =>
          queryOptions?.valueField ? option[queryOptions?.valueField] : option.id
        }
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          handleInputChange({
            target: { value: newInputValue }
          } as React.ChangeEvent<HTMLInputElement>)
        }}
        onChange={handleSelectionChange}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {queryOptions?.labelField ? option[queryOptions.labelField] : option.title}
          </li>
        )}
        renderInput={params => (
          <TextField {...params} label={label} variant="outlined" inputRef={ref} />
        )}
      />
    )
  }
)

export default AutoComplete
