import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { AddCircleOutline } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import { IAddButton } from './types'

const AddButton: FC<IAddButton> = ({ setOpen }) => {
  const { t } = useTranslation('common')
  return (
    <Tooltip title={t('companySearch.addNewPreset')}>
      <IconButton onClick={() => setOpen(true)} size="small">
        <AddCircleOutline />
      </IconButton>
    </Tooltip>
  )
}
export default AddButton
