import React, { useState, forwardRef } from 'react'
import { Grid } from '@mui/material'
import AutoComplete, { IAutoComplete } from '../../atoms/AutoComplete'
import { AddButton, Modal } from './components'

interface ICompanyAutoComplete extends IAutoComplete {
  onSavePreset?: (val: any) => void
}

const CompanyAutoComplete = forwardRef(
  (
    { query, queryOptions, onSelected, onChange, onSavePreset, value, label }: ICompanyAutoComplete,
    ref
  ): JSX.Element => {
    const [open, setOpen] = useState(false)

    const handleSavePreset = (val: any) => {
      onSavePreset?.(val)
      setOpen(false)
    }
    const handleAbortNewPreset = () => {
      setOpen(false)
    }

    return (
      <Grid container spacing={1}>
        <Grid item xs={10}>
          <AutoComplete
            query={query}
            queryOptions={queryOptions}
            onSelected={onSelected}
            onChange={onChange}
            value={value}
            label={label}
            ref={ref}
          />
        </Grid>
        <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <AddButton setOpen={setOpen} />
        </Grid>
        <Modal open={open} onAbort={handleAbortNewPreset} onSave={handleSavePreset} />
      </Grid>
    )
  }
)

export default CompanyAutoComplete
