import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import { Search } from '../../../assets/icons'
import { Input, Button, QuerySelect, Select } from '../../atoms'

const valueTypes = ['NULL', '!NULL', 'NULL_OR_EMPTY', '!NULL_OR_EMPTY']

const SearchField = ({
  id,
  onSubmit,
  label,
  minWidth,
  maxWidth,
  defaultValue,
  type,
  query,
  queryOptions,
  noQuery,
  options,
  multiple
}) => {
  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue, setValue])

  const renderDropdown = () =>
    noQuery ? (
      <Select
        native
        items={options}
        onChange={val => {
          let newSelected = val
          if (multiple) {
            newSelected = [...value]
            const selectedIndex = newSelected.indexOf(val)
            if (selectedIndex === -1) {
              newSelected.push(val)
            } else {
              newSelected.splice(selectedIndex, 1)
            }
          }
          setValue(newSelected)
        }}
        value={valueTypes.includes(value) ? '' : value}
        label={label}
        multiple={multiple}
        minWidth={minWidth}
        maxWidth={maxWidth}
      />
    ) : (
      <QuerySelect
        native
        label={label}
        name={queryOptions?.variables?.filter?.lookupType || undefined}
        query={query}
        options={queryOptions}
        value={valueTypes.includes(value) ? '' : value}
        onChange={val => setValue(val)}
        multiple={multiple}
        minWidth={minWidth}
        maxWidth={maxWidth}
      />
    )
  const cleanupInputValue = val => {
    let cleanedValue = typeof val === 'string' ? val : ''
    if (valueTypes.includes(cleanedValue)) {
      cleanedValue = ''
    }
    return cleanedValue
  }
  return (
    <form
      id={`filter-form-${id}`}
      name={`filter-form-${id}`}
      onSubmit={e => {
        e.preventDefault()
        onSubmit(value, e)
        return false
      }}
    >
      <Box
        sx={theme => ({
          display: 'flex',
          flexDirection: 'row',
          [theme.breakpoints.down('md')]: { flexDirection: 'column' }
        })}
      >
        {type === 'dropdown' || type === 'dropdownNumber' ? (
          renderDropdown()
        ) : (
          <Input
            autoFocus
            style={{ minWidth }}
            label={label}
            value={cleanupInputValue(value)}
            onChange={({ target: { value: val } }) => setValue(val)}
            type={type === 'euro' ? 'money' : type}
          />
        )}
        <Button
          sx={theme => ({
            marginLeft: '6px',
            maxWidth: '48px',
            maxHeight: '42px',
            [theme.breakpoints.down('md')]: { marginLeft: '0px', marginTop: '6px', height: '40px' }
          })}
          type="submit"
          form={`filter-form-${id}`}
          size="small"
          variant="contained"
          color="secondary"
        >
          <Search />
        </Button>
      </Box>
    </form>
  )
}
SearchField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onSubmit: PropTypes.func,
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  query: PropTypes.object,
  queryOptions: PropTypes.object,
  noQuery: PropTypes.bool,
  options: PropTypes.object,
  multiple: PropTypes.bool
}
SearchField.defaultProps = {
  id: '',
  label: null,
  defaultValue: null,
  onSubmit: () => {},
  minWidth: undefined,
  maxWidth: undefined,
  type: 'default',
  query: undefined,
  queryOptions: undefined,
  noQuery: false,
  options: undefined,
  multiple: false
}

export default SearchField
